











































import { defineComponent } from '@nuxtjs/composition-api';

interface Column extends Blok {
  marginTop: number;
  items: Blok[];
}

type GridProps = Blok & {
  gap: number;
  items: Column[];
  marginTop: number;
  verticalAlignment: 'flex-start' | 'center' | 'flex-end';
};

export default defineComponent({
  props: {
    blok: { type: Object as () => GridProps, required: true },
  },

  computed: {
    // Does this Grid blok contain only two columns and
    // only one of them contains a single media component?
    isMediaPlusCopyGrid(): boolean {
      const { items } = this.blok;

      return (
        items?.length === 2 &&
        items.every((col) => col.component === 'grid-column') &&
        items.reduce(
          (sum, col) => sum + (this.colIsMediaOnly(col) ? 1 : 0),
          0
        ) === 1
      );
    },

    cssVars(): CSSVars {
      return {
        gap: this.blok.gap ? `${this.blok.gap}rem` : 0,
        marginTop: this.blok.marginTop ? `${this.blok.marginTop}rem` : 0,
      };
    },
  },
  methods: {
    colIsMediaOnly(col: Column): boolean {
      const mediaTypes = ['an-image', 'an-lottie-player', 'video-player'];

      return (
        col.items?.length === 1 && mediaTypes.includes(col.items[0].component)
      );
    },
  },
});
